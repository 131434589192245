<script setup lang="ts">
import { SvgoHpWhyEasyAuctions, SvgoHpWhyEuReach, SvgoHpWhyOperationalEfficiency, SvgoHpWhySell, SvgoHpWhyUnifiedBilling } from '#components';
import BlockFeatItemPrimary from '~/components/Block/BlockFeatItemPrimary.vue';
import type { BlockFeatThreeColItem } from '~/components/Block/BlockFeatThreeCol.vue';

// COMPOSABLES
const { tt } = useTypedI18n();
const { sellLink } = useNavMenuItems();

// DATA
const items = computed<BlockFeatThreeColItem[]>(() => [
    {
        icon: SvgoHpWhyEuReach,
        title: tt('homepage.whyFleequid.section.euReach.title'),
        description: tt('homepage.whyFleequid.section.euReach.description'),
        align: 'left',
    },
    {
        icon: SvgoHpWhyUnifiedBilling,
        title: tt('homepage.whyFleequid.section.unifiedBilling.title'),
        description: tt('homepage.whyFleequid.section.unifiedBilling.description'),
        align: 'left',
    },
    {
        icon: SvgoHpWhyEasyAuctions,
        title: tt('homepage.whyFleequid.section.easyAuctions.title'),
        description: tt('homepage.whyFleequid.section.easyAuctions.description'),
        align: 'left',
    },
    {
        icon: SvgoHpWhyOperationalEfficiency,
        title: tt('homepage.whyFleequid.section.operationalEfficiency.title'),
        description: tt('homepage.whyFleequid.section.operationalEfficiency.description'),
        align: 'left',
    },
    {
        slot: 'sell',
        icon: SvgoHpWhySell,
        title: tt('homepage.whyFleequid.section.sell.title'),
        description: tt('homepage.whyFleequid.section.sell.description'),
    },
]);
</script>

<template>
    <BlockFeatThreeCol
        :title="tt('homepage.whyFleequid.title')"
        :description="tt('homepage.whyFleequid.description')"
        :items="items"
        grid-class="md:md:grid-cols-2 lg:md:grid-cols-3"
    >
        <template #sell="{ item }">
            <BlockFeatItemPrimary :description="item.description" :title="item.title" :icon="item.icon" :align="'left'">
                <UButton
                    size="xl"
                    variant="link"
                    color="white"
                    :to="sellLink"
                    target="_blank"
                    trailing-icon="i-mdi-keyboard-arrow-right"
                    class="underline hover:no-underline"
                    :padded="false"
                >
                    {{ tt('homepage.whyFleequid.section.sell.cta') }}
                </UButton>
            </BlockFeatItemPrimary>
        </template>
    </BlockFeatThreeCol>
</template>
